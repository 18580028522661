import { FETCH_PRICE_TAGS_SUCCESS } from "../actions/productActions";

const initState = {
    priceTags: [],
};

const priceTagsReducer = (state = initState, action) => {
    if (action.type === FETCH_PRICE_TAGS_SUCCESS) {
        return {
            ...state,
            priceTags: action.payload
        };
    }

    return state;
};

export default priceTagsReducer;
