import {
  ADD_TO_WISHLIST,
  DELETE_FROM_WISHLIST,
  DELETE_ALL_FROM_WISHLIST,
  RESET_WISHLIST,
  REPLACE_WISHLIST
} from "../actions/wishlistActions";
import * as userApi from './../../api/user'

const initState = [];

const wishlistReducer = (state = initState, action) => {
  var wishlistPayload = []

  const dynamicWishList = (wishlistPayload) => {
    const usertokenSatyamUser = localStorage.usertokenSatyamUser
      if (usertokenSatyamUser != null) {
        var data = {
          userToken: usertokenSatyamUser,
          wishlistItem: wishlistPayload
        }

          userApi.addToWishlist(data)
            .then(async res => {
              if (res.status == 200) {
              
              } else if (res.status == 403) {
                // alert('Session Expired..Please Login Again..!')
                // localStorage.clear()
                // window.location = process.env.PUBLIC_URL + "/auth/login-register"
              } else {
                // alert('Something Went Wrong')
                // localStorage.clear()
                // window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
              }
            })
            .catch((err) => {
              // alert('Something Went Wrong')
              // localStorage.clear()
              // window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
            })
        }
  }

  const wishlistItems = state,
    product = action.payload;

  if (action.type === ADD_TO_WISHLIST) {
    const wishlistItem = wishlistItems.filter(
      item => item.id === product.id
    )[0];
    if (wishlistItem === undefined) {
      wishlistPayload = [...wishlistItems, product];
      dynamicWishList(wishlistPayload);
      return wishlistPayload;
    } else {
      wishlistPayload =  wishlistItems;
      dynamicWishList(wishlistPayload);
      return wishlistPayload;
    }
  }

  if (action.type === DELETE_FROM_WISHLIST) {
    const remainingItems = (wishlistItems, product) =>
      wishlistItems.filter(wishlistItem => wishlistItem.id !== product.id);

      wishlistPayload = remainingItems(wishlistItems, product);
      dynamicWishList(wishlistPayload);
    return wishlistPayload;
  }

  if (action.type === DELETE_ALL_FROM_WISHLIST) {
    wishlistPayload = wishlistItems.filter(item => {
      return false;
    });
    dynamicWishList(wishlistPayload);
    return wishlistPayload;
  }

  if (action.type === RESET_WISHLIST) {
    wishlistPayload = []
    dynamicWishList(wishlistPayload);
    return wishlistPayload;
  }

  if (action.type === REPLACE_WISHLIST) {
    wishlistPayload = action.wishlist
    dynamicWishList(wishlistPayload);
    return wishlistPayload;
  }

  return wishlistItems;
};

export default wishlistReducer;
