import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";

import ScrollToTop from "./helpers/scroll-top";

const Landing = lazy(() => import("./pages/Landing"));

// Main pages
const ItemsShopGrid = lazy(() => import("./pages/ItemsShopGrid"));
const ShopCategory = lazy(() => import("./pages/shopgridpages/ShopCategory"));
const ShopSubCategory = lazy(() => import("./pages/shopgridpages/ShopSubCategory"));

const ProductDetails = lazy(() => import("./pages/ProductDetails"));

// other pages
const Contact = lazy(() => import("./pages/other/Contact"));
const Reseller = lazy(() => import("./pages/other/ResellerForm"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const UserOrders = lazy(() => import("./pages/other/UserOrders"));
const LoginRegister = lazy(() => import("./pages/auth/LoginRegister"));
const VerifyPhone = lazy(() => import("./pages/other/VerifyPhone"));
const ResellerOnboardingSuccess = lazy(() => import("./pages/other/ResellerOnboardingSuccess"));
const ConfirmAccount = lazy(() => import("./pages/other/ConfirmAccount"));
const ForgetPassword = lazy(() => import("./pages/other/ForgetPassword"));
const ResetPassword = lazy(() => import("./pages/other/ResetPassword"));
const About = lazy(() => import("./pages/other/About"));
const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const OrderSuccess = lazy(() => import("./pages/other/OrderSuccess"));
const Terms = lazy(() => import("./pages/other/Terms"));
const FAQs = lazy(() => import("./pages/other/FAQs"));
const ShippingDeliveryPolicy = lazy(() => import("./pages/other/ShippingDeliveryPolicy"));
const DisclaimerPolicy = lazy(() => import("./pages/other/DisclaimerPolicy"));
const PrivacyPolicy = lazy(() => import("./pages/other/PrivacyPolicy"));
const CancellationRefundPolicy = lazy(() => import("./pages/other/CancellationRefundPolicy"));
const ConfirmAccountWtihPhone = lazy(() => import("./pages/other/ConfirmAccountWtihPhone"))
const VerifyPhoneNo = lazy(() => import("./pages/other/VerifyPhoneNo"))
const Transactions = lazy(() => import("./pages/other/Transactions"));
const UserWithdrawals = lazy(() => import("./pages/other/UserWithdrawals"));

//Orders
const orderDetails = lazy(() => import("./pages/other/OrderDetails"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const PartnerDashboard = lazy(() => import("./pages/other/PartnerDashboard"));

const App = props => {
  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          fn: require("./translations/french.json"),
          de: require("./translations/germany.json")
        }
      })
    );
  });

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <img alt="" src={process.env.PUBLIC_URL + 'assets/img/logo/logomonogram.png'} style={{ width: '100%' }} />
                  </div>
                </div>
              }
            >
              <Switch>
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={Landing}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid"}
                  component={ItemsShopGrid}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop/:category/:subcategory/:pricetag"}
                  component={ShopCategory}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shopcategory/:subcategory"}
                  component={ShopSubCategory}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/details/:id"}
                  component={ProductDetails}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/become-reseller"}
                  component={Reseller}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/partner-program/dashboard"}
                  component={PartnerDashboard}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/my-account"}
                  component={MyAccount}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/all-transactions"}
                  component={Transactions}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/withdrawal-requests"}
                  component={UserWithdrawals}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/orders"}
                  component={UserOrders}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/order-details:orderid"}
                  component={orderDetails}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/login-register"}
                  component={LoginRegister}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/verify-mobile_number"}
                  component={VerifyPhone}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/verify-phone/:token"}
                  component={VerifyPhoneNo}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/reseller-onboarding-success/:slug"}
                  component={ResellerOnboardingSuccess}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/confirm/:token"}
                  component={ConfirmAccount}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/confirm-phone/:token"}
                  component={ConfirmAccountWtihPhone}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/forget-password"}
                  component={ForgetPassword}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/reset-password/:token"}
                  component={ResetPassword}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/wishlist"}
                  component={Wishlist}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/compare"}
                  component={Compare}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/checkout"}
                  component={Checkout}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/order-success"}
                  component={OrderSuccess}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/terms"}
                  component={Terms}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/faq"}
                  component={FAQs}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/ship&delivery-policy"}
                  component={ShippingDeliveryPolicy}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/disclaimer-policy"}
                  component={DisclaimerPolicy}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/privacy-policy"}
                  component={PrivacyPolicy}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/cancel&refund-policy"}
                  component={CancellationRefundPolicy}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />
                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(multilanguage(App));
