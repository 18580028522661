import axios from 'axios';

export const fetchProducts = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/fetchproducts`, {})
        .then(res => { return res.data })
        .catch(err => { return err; })
};

export const fetchProductsDetails = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/fetchsingleproducts`, data)
        .then(res => { return res.data })
        .catch(err => { return err; })
};

export const fetchCategories = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/fetchcategories`, {})
        .then(res => { return res.data.data; })
        .catch(err => { return err; })
};

export const fetchIntroSliders = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/fetchintrosliders`, {})
        .then(res => { return res.data.data; })
        .catch(err => { return err; })
};

export const fetchClientTestimonials = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/fetch-client-testimonials`, {})
        .then(res => { return res.data.data; })
        .catch(err => { return err; })
};

export const fetchShowoffGallery = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/fetch-showoff-gallery`, {})
        .then(res => { return res.data.data; })
        .catch(err => { return err; })
};

export const fetchTestimonials = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/fetch-testimonials`, {})
        .then(res => { return res.data.data; })
        .catch(err => { return err; })
};

export const fetchColors = () => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/fetchColors`, {})
        .then(res => { return res.data.data; })
        .catch(err => { return err; })
};

export const fetchPrdVariants = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/fetchprdvariants`, data)
        .then(res => { return res.data.data; })
        .catch(err => { return err; })
};

export const fetchPrdColors = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/fetchprdcolors`, data)
        .then(res => { return res.data.data; })
        .catch(err => { return err; })
};

export const fetchPrdDeals = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/fetchprddeals`, data)
        .then(res => { return res.data.data; })
        .catch(err => { return err; })
};

export const fetchPriceTags = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/productrequest/fetchPriceTags`, data)
        .then(res => { return res.data.data; })
        .catch(err => { return err; })
};

export const fetchLandingBanner = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/otherrequest/fetchlandingbanner`, data)
        .then(res => { return res.data.data; })
        .catch(err => { return err; })
};