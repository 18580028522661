import { FETCH_PRD_DEALS_SUCCESS } from "../actions/productActions";

const initState = {
    prdDeals: [],
};

const prdDealsReducer = (state = initState, action) => {
    if (action.type === FETCH_PRD_DEALS_SUCCESS) {
        return {
            ...state,
            prdDeals: action.payload
        };
    }

    return state;
};

export default prdDealsReducer;
