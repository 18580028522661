import { FETCH_PRD_COLORS_SUCCESS } from "../actions/productActions";

const initState = {
    prdColors: [],
};

const prdColorsReducer = (state = initState, action) => {
    if (action.type === FETCH_PRD_COLORS_SUCCESS) {
        return {
            ...state,
            prdColors: action.payload
        };
    }
    return state;
};

export default prdColorsReducer;
