import currencyReducer from "./currencyReducer";
import productReducer from "./productReducer";
import catsReducer from "./catsReducer";
import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import colorsReducer from "./colorsReducer";
import prdColorsReducer from './prdColorsReducer'
import prdDealsReducer from './prdDealsReducer'
import priceTagsReducer from './priceTagsReducer'

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  currencyData: currencyReducer,
  productData: productReducer,
  catsData: catsReducer,
  colorsData: colorsReducer,
  prdColorsData: prdColorsReducer,
  cartData: cartReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer,
  prdDealsData: prdDealsReducer,
  priceTagData: priceTagsReducer
});

export default rootReducer;
