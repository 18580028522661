import axios from 'axios';

export const fetchUserData = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/fetchuserdata`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchRechargeRequests = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/fetchrechargerequests`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchWithdrawlRequests = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/fetchwithdrawlrequests`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const transferFromRechargeToNormal = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/transferfromrechargetonormal`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const makeWithdrawlRequest = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/makewithdrawlrequest`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchDownlineStructure = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/fetchdownlinestructure`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const verifyFirstOrder = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/verifyfirstorder`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const updateProfile = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/updateprofile`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const removeProfilePicture = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/removeprofilepicture`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const uploadProfilePicture = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/uploadprofilepicture`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const makeRechargeRequest = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/makerechargerequest`, data)
        .then(res => { return res.data; })
        .catch(err => { return err; })
};

export const fetchWishlistANDCartlist = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/fetchwishlistandcartlist`, data)
        .then(res => { return res.data; })
        .catch(err => { return err.response; })
};

export const addToWishlist = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/addtowishlist`, data)
        .then(res => { return res.data; })
        .catch(err => { return err.response; })
};

export const removeFromWishlist = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/removefromwishlist`, data)
        .then(res => { return res.data; })
        .catch(err => { return err.response; })
};

export const addToCartlist = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/addtocartlist`, data)
        .then(res => { return res.data; })
        .catch(err => { return err.response; })
};

export const removeFromCartlist = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/removefromcartlist`, data)
        .then(res => { return res.data; })
        .catch(err => { return err.response; })
};

export const removeFromWishlistANDaddToCartlist = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/removefromwishlistandaddTocartlist`, data)
        .then(res => { return res.data; })
        .catch(err => { return err.response; })
};

export const updateCartlistQty = (data) => {
    return axios
        .post(`${process.env.PUBLIC_URL}/userrequest/updatecartlistqty`, data)
        .then(res => { return res.data; })
        .catch(err => { return err.response; })
};
