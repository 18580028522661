import uuid from "uuid/v4";
import {
  ADD_TO_CART,
  DECREASE_QUANTITY,
  DELETE_FROM_CART,
  DELETE_ALL_FROM_CART,
  RESET_CARTLIST,
  REPLACE_CARTLIST
} from "../actions/cartActions";
import * as userApi from './../../api/user'

const initState = [];

const cartReducer = (state = initState, action) => {
  var cartPayload = []

  const dynamicCart = (cartPayload) => {
    const usertokenSatyamUser = localStorage.usertokenSatyamUser
      if (usertokenSatyamUser != null) {
        var data = {
          userToken: usertokenSatyamUser,
          cartlistItem: cartPayload
        }

          userApi.addToCartlist(data)
            .then(async res => {
              if (res.status == 200) {
              
              } else if (res.status == 403) {
                // alert('Session Expired..Please Login Again..!')
                // localStorage.clear()
                // window.location = process.env.PUBLIC_URL + "/auth/login-register"
              } else {
                // alert('Something Went Wrong')
                // localStorage.clear()
                // window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
              }
            })
            .catch((err) => {
              // alert('Something Went Wrong')
              // localStorage.clear()
              // window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
            })
        }
  }

  const cartItems = state,
    product = action.payload;

  if (action.type === ADD_TO_CART) {
    // for non variant products
    if (product.variation === undefined) {
      const cartItem = cartItems.filter(item => item.id === product.id)[0];
      if (cartItem === undefined) {
        cartPayload = [
          ...cartItems,
          {
            ...product,
            quantity: product.quantity ? product.quantity : 1,
            cartItemId: uuid()
          }
        ];

        dynamicCart(cartPayload);
        return cartPayload;

      } else {
        cartPayload = cartItems.map(item =>
          item.cartItemId === cartItem.cartItemId
            ? {
              ...item,
              quantity: product.quantity
                ? item.quantity + product.quantity
                : item.quantity + 1
            }
            : item
        );
        dynamicCart(cartPayload);
        return cartPayload;
      }
      // for variant products
    } else {
      const cartItem = cartItems.filter(
        item =>
          item.id === product.id &&
          product.selectedProductColor &&
          product.selectedProductColor === item.selectedProductColor &&
          product.selectedProductSize &&
          product.selectedProductSize === item.selectedProductSize &&
          (product.cartItemId ? product.cartItemId === item.cartItemId : true)
      )[0];

      if (cartItem === undefined) {
        cartPayload = [
          ...cartItems,
          {
            ...product,
            quantity: product.quantity ? product.quantity : 1,
            cartItemId: uuid()
          }
        ];
        dynamicCart(cartPayload);
        return cartPayload;
      } else if (
        cartItem !== undefined &&
        (cartItem.selectedProductColor !== product.selectedProductColor ||
          cartItem.selectedProductSize !== product.selectedProductSize)
      ) {
        cartPayload = [
          ...cartItems,
          {
            ...product,
            quantity: product.quantity ? product.quantity : 1,
            cartItemId: uuid()
          }
        ];
        dynamicCart(cartPayload);
        return cartPayload;
      } else {
        cartPayload = cartItems.map(item =>
          item.cartItemId === cartItem.cartItemId
            ? {
              ...item,
              quantity: product.quantity
                ? item.quantity + product.quantity
                : item.quantity + 1,
              selectedProductColor: product.selectedProductColor,
              selectedProductSize: product.selectedProductSize
            }
            : item
        );
        dynamicCart(cartPayload);
        return cartPayload;
      }
    }
  }

  if (action.type === DECREASE_QUANTITY) {
    if (product.quantity === 1) {
      const remainingItems = (cartItems, product) =>
        cartItems.filter(
          cartItem => cartItem.cartItemId !== product.cartItemId
        );
      cartPayload = remainingItems(cartItems, product);
      dynamicCart(cartPayload);
      return cartPayload;
    } else {
      cartPayload = cartItems.map(item =>
        item.cartItemId === product.cartItemId
          ? { ...item, quantity: item.quantity - 1 }
          : item
      );

      dynamicCart(cartPayload);
      return cartPayload;
    }
  }

  if (action.type === DELETE_FROM_CART) {
    const remainingItems = (cartItems, product) =>
    cartItems.filter(cartItem => cartItem.cartItemId !== product.cartItemId);
    
    cartPayload = remainingItems(cartItems, product);

    dynamicCart(cartPayload);
    return cartPayload;
  }

  if (action.type === DELETE_ALL_FROM_CART) {

    cartPayload = cartItems.filter(item => {
      return false;
    });
    dynamicCart(cartPayload);
    return cartPayload;
  }

  if (action.type === RESET_CARTLIST) {
    cartPayload = []

    dynamicCart(cartPayload);
    return cartPayload;
  }

  if (action.type === REPLACE_CARTLIST) {
    cartPayload = action.cartlist
    dynamicCart(cartPayload);
    return cartPayload
  }

  // const usertokenSatyamUser = localStorage.usertokenSatyamUser
  // if (usertokenSatyamUser != null) {
  //   var data = {
  //     userToken: usertokenSatyamUser,
  //     cartlistItem: state
  //   }

  //     userApi.addToCartlist(data)
  //       .then(async res => {
  //         if (res.status == 200) {
  //         //   if(!window.location.hash) {
  //         //     window.location = window.location + '#loaded';
  //         //     window.location.reload();
  //         // }  
  //           // window.location.reload()
  //         } else if (res.status == 403) {
  //           // alert('Session Expired..Please Login Again..!')
  //           // localStorage.clear()
  //           // window.location = process.env.PUBLIC_URL + "/auth/login-register"
  //         } else {
  //           // alert('Something Went Wrong')
  //           // localStorage.clear()
  //           // window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
  //         }
  //       })
  //       .catch((err) => {
  //         // alert('Something Went Wrong')
  //         // localStorage.clear()
  //         // window.location = process.env.PUBLIC_URL + "/auth/login-register/:data"
  //       })
  //   }
  return state;
};

export default cartReducer;
