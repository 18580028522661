import { FETCH_CATS_SUCCESS } from "../actions/productActions";

const initState = {
  cats: [],
};

const catsReducer = (state = initState, action) => {
  if (action.type === FETCH_CATS_SUCCESS) {
    return {
      ...state,
      cats: action.payload
    };
  }

  return state;
};

export default catsReducer;
