import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";

import App from "./App";
import { fetchProducts, fetchCats, fetchColors, fetchPrdColors, fetchPrdDeals, fetchPriceTags } from "./redux/actions/productActions";
import rootReducer from "./redux/reducers/rootReducer";
import * as api from './api'

//import products from "./data/products.json";

// import axios from "axios";

import "./assets/scss/style.scss";

import * as serviceWorker from "./serviceWorker";

import { composeWithDevTools } from "redux-devtools-extension";

import { fetchWishlistANDCartlist } from './api/user'
import { resetWishlist, replaceWishlist } from './redux/actions/wishlistActions';
import { resetCartlist, replaceCartlist } from './redux/actions/cartActions';

const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save()))
);

// const _products = axios.get(`https://administer.satyampaithani.com/products`)
//   .then((response) => {
//     return response.data;
//   })
//   .then((data) => {
//     // fetch products from json file
//     store.dispatch(fetchProducts(data));
//   })
//   .catch(err => {
//     return err.response;
//   });

//Products

const trymeFn = async () => {
  await api.fetchProducts()
    .then((response) => {
      store.dispatch(fetchProducts(response.data));
      return response.data;
    })
    .catch(err => {
      return err.response;
    });
}
try {
  trymeFn()
} catch (error) {
  // console.log(error)
}

//categories
try {
  const _categories = api.fetchCategories()
    .then((response) => {
      store.dispatch(fetchCats(response));
    })
    .catch(err => {
      return err.response;
    });

} catch (error) {
}

//colors
try {
  const _colors = api.fetchColors()
    .then((response) => {
      store.dispatch(fetchColors(response));
    })
    .catch(err => {
      return err.response;
    });

} catch (error) {
}

//prdColors
try {
  const _prdColors = api.fetchPrdColors()
    .then((response) => {
      store.dispatch(fetchPrdColors(response));
    })
    .catch(err => {
      return err.response;
    });

} catch (error) {
}

//prdDeals
try {
  const _prdDeals = api.fetchPrdDeals()
    .then((response) => {
      store.dispatch(fetchPrdDeals(response));
    })
    .catch(err => {
      return err.response;
    });

} catch (error) {
}

//Price Tags
try {
  const _priceTags = api.fetchPriceTags()
    .then((response) => {
      store.dispatch(fetchPriceTags(response));
    })
    .catch(err => {
      return err.response;
    });

} catch (error) {
}

//Cart and Wishlist
try {
  const usertokenSatyamUser = localStorage.usertokenSatyamUser
  if (usertokenSatyamUser != null) {
    const data = {
      userToken: usertokenSatyamUser,
    }
    fetchWishlistANDCartlist(data)
      .then(async res => {
        if (res.status == 200) {
          store.dispatch(replaceWishlist(res.data.wishlist))
          store.dispatch(replaceCartlist(res.data.cartlist))
        } else if (res.status == 403 && res.error == res.error) {
          alert('Session Expired..Please Login Again..!')
          // localStorage.removeItem('usertokenShivshahiCustomer')
          localStorage.clear();
          window.location = process.env.PUBLIC_URL + "/login-register"
        } else {
          alert('Something Went Wrong')
          // localStorage.removeItem('usertokenShivshahiCustomer')
          localStorage.clear();
          // store.dispatch(resetWishlist())
          // store.dispatch(resetCartlist())
          window.location = process.env.PUBLIC_URL + "/login-register"
        }
      })
      .catch((err) => {
        alert('Something Went Wrong')
        // localStorage.removeItem('usertokenShivshahiCustomer')
        localStorage.clear();
        // store.dispatch(resetWishlist())
        // store.dispatch(resetCartlist())
        window.location = process.env.PUBLIC_URL + "/login-register"
      })
  } else {
    // store.dispatch(resetWishlist())
    // store.dispatch(resetCartlist())
    if (window.location.pathname == '/wishlist' || window.location.pathname == '/cart' || window.location.pathname == '/checkout') {
      window.location = process.env.PUBLIC_URL + "/login-register"
    }
  }
} catch (error) {
  // return error.response;
}


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

serviceWorker.register();
