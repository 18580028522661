export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_CATS_SUCCESS = "FETCH_CATS_SUCCESS";
export const FETCH_COLORS_SUCCESS = "FETCH_COLORS_SUCCESS";
export const FETCH_PRD_COLORS_SUCCESS = "FETCH_PRD_COLORS_SUCCESS";
export const FETCH_PRD_DEALS_SUCCESS = "FETCH_PRD_DEALS_SUCCESS";
export const FETCH_PRICE_TAGS_SUCCESS = "FETCH_PRICE_TAGS_SUCCESS";

const fetchProductsSuccess = products => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products
});

// fetch products
export const fetchProducts = products => {
  return dispatch => {
    dispatch(fetchProductsSuccess(products));
  };
};

const fetchCatsSuccess = cats => ({
  type: FETCH_CATS_SUCCESS,
  payload: cats
});

// fetch cats
export const fetchCats = cats => {
  return dispatch => {
    dispatch(fetchCatsSuccess(cats));
  };
};

const fetchColorsSuccess = colors => ({
  type: FETCH_COLORS_SUCCESS,
  payload: colors
});

// fetch colors
export const fetchColors = colors => {
  return dispatch => {
    dispatch(fetchColorsSuccess(colors));
  };
};

const fetchPrdColorsSuccess = prdColors => ({
  type: FETCH_PRD_COLORS_SUCCESS,
  payload: prdColors
});

// fetch prdColors
export const fetchPrdColors = prdColors => {
  return dispatch => {
    dispatch(fetchPrdColorsSuccess(prdColors));
  };
};

// fetch prdDeals
const fetchPrdDealsSuccess = prdDeals => ({
  type: FETCH_PRD_DEALS_SUCCESS,
  payload: prdDeals
});

export const fetchPrdDeals = prdDeals => {
  return dispatch => {
    dispatch(fetchPrdDealsSuccess(prdDeals));
  };
};

// fetch prdDeals
const fetchPriceTagsSuccess = priceTags => ({
  type: FETCH_PRICE_TAGS_SUCCESS,
  payload: priceTags
});

export const fetchPriceTags = priceTags => {
  return dispatch => {
    dispatch(fetchPriceTagsSuccess(priceTags));
  };
};

// export const fetchProducts = () => (dispatch) => {
//   dispatch(fetchProductsBegin());
//   return (products) => {
//     products.then((data) => {
//       dispatch(fetchProductsSuccess(data));
//     });
//   };
// };
